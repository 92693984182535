export function useCta() {
  const config = useRuntimeConfig()

  function handleLogin() {
    navigateTo(`${config.public.dmatWebUrl}/auth/login`, { external: true })
  }
  function handleRegister() {
    navigateTo(`${config.public.dmatWebUrl}/auth/register/email`, { external: true })
  }

  function handleCTA() {
    navigateTo(`${config.public.dmatWebUrl}/`, { external: true })
  }

  return {
    handleLogin,
    handleRegister,
    handleCTA,
  }
}
